import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/services/common.service';
import { EventsService } from 'src/services/events.service';
import { SeatDialogComponent } from 'src/shared/seat-dialog/seat-dialog.component';
@Component({
  selector: 'app-group-attendee',
  templateUrl: './group-attendee.component.html',
  styleUrls: ['./group-attendee.component.scss']
})
export class GroupAttendeeComponent {

  groupRegisterEventForm: FormGroup;
  attendees: any[] = [];
  editingIndex: number | null = null;
  eventDetailsId: any;
  questionType: any;
  dialogRef: any;
  isShow: boolean = false;
  registrationFormData: any = null;
  seasons: any = ['Yes', 'No'];
  editAttendeeFlag: boolean = false;
  addAttendeeFlg: boolean = true;
  eventDetail: any;
  hideOptions = false;
  textOnlyRegex: RegExp = /^([a-zA-Z]+[ "'\-\.]*[a-zA-Z]+)+$/;
  emailRegex: RegExp = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,15})?$/;
  mobileRegex: RegExp = /^(?:\((?=.*\)))?([2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
  showSeatFlag: boolean = false;
  value: any;
  seat: any;
  data: any;
  attendeecartdData: any;
  dynamicQuestions: any = [];
  allQuestionType: any = [];
  token: any;
  isAuth: boolean;
  maxiMumGroupSize: any
  isSubmitted: boolean = false
  questionList: any = [];
  passObjresp: any;
  questionsList: any;
  eventDetailData: any;
  registrationCountLeft: number;

  // isGrpLeader: boolean = false;

  constructor(public router: Router,
    private fb: FormBuilder,
    public commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    public _dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private eventService: EventsService,
    private toaster: ToastrService) {

    this.commonService.attendeecartdDatasubmit.subscribe(regResp => {

      this.attendeecartdData = regResp;
    });
    this.commonService.groupRegistrationFormSubject.subscribe((regResp) => {
      this.registrationFormData = regResp;
    })

    this.commonService.passDataObj.subscribe((passResponce) => {
      this.passObjresp = passResponce
    })

  }


  ngOnInit() {
    this.eventDetailsId = this.activatedRoute.snapshot.paramMap.get('id');
    this.value = this.activatedRoute.snapshot.queryParamMap.get('value');
    this.data = this.activatedRoute.snapshot.queryParamMap.get('Type');
    this.token = this.activatedRoute.snapshot.queryParamMap.get('Token');
    // this.seat = parseInt(this.activatedRoute.snapshot.queryParamMap.get('seat'));
    let obj = {
      data: this.data,
      token: this.token
    }
    const isClaimed = sessionStorage.getItem('isClaimed');
    const isCrm = sessionStorage.getItem('isCrm');


    if ((isClaimed != '0') && (isCrm != '0')) {
      this.router.navigate(['/unauthorized']);
    }
    this.commonService.crmUrlData.next(obj);
    this.groupRegisterEventForm = this.fb.group({
      firstName: [null, [Validators.required, Validators.pattern(this.textOnlyRegex)]],
      lastName: [null, [Validators.required, Validators.pattern(this.textOnlyRegex)]],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      mobileNumber: ['', [Validators.required, Validators.pattern(this.mobileRegex)]],
      seatnumber: [''],
      isGroupLeader: false,
      jobTitle : ['' , Validators.required],
      // companyName: ['']
    });
    this.GetEventDetailsByEventByEventId()
    this.questionDependent();
    this.getEventDetail();

    if (this.registrationFormData == null) {
      this.router.navigate(['/event-registration/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
    }

  }
  GetEventDetailsByEventByEventId() {
    this.spinner.show();
    this.eventService.GetEventDetailsByEventByEventId(this.eventDetailsId).subscribe((respEventDetails: any) => {
      if (respEventDetails.isSuccessful === true) {
        this.seat = respEventDetails?.data?.eventSeatAvailable;
        this.maxiMumGroupSize = respEventDetails?.data?.maximumGroupSize;
        this.eventDetailData = respEventDetails?.data
        this.registrationCountLeft = respEventDetails?.data?.maximumEventCapacity - respEventDetails?.data?.eventRegistrationCount

        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    })
    this.spinner.hide();
  }
  patchCardForAttendees() {
    this.attendeecartdData.forEach((element: any) => {
      const ownerIsAttendeeData: any = {
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.email,
        mobileNumber: element.mobileNumber.replace('+61', ''),
        seatnumber: element.seatnumber,
        isGroupLeader: element.isGroupLeader,
        jobTitle : element.jobTitle
      }
      this.commonService.groupRegistrationFormSubject.subscribe((regResp) => {
        this.registrationFormData = regResp;
      });

      if (element?.attendeeCustomQuestionLists?.length) {
        element.attendeeCustomQuestionLists.forEach((item: any) => {
          const { customQuestionId, response } = item;
          ownerIsAttendeeData[customQuestionId] = response;
        });
        element?.attendeeCustomQuestionLists?.forEach((x: any) => {
          if (typeof x.response === 'string' && x.response.includes(',')) {
            x.response = x.response.split(',').map((item: string) => item.trim());
          }
        });

      }
      this.spinner.hide();
      this.attendees.push(ownerIsAttendeeData);

    });

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    const inputValue = event.target.value;
  
    // Allow digits, backspace, delete, and the plus sign (+)
    if (
      (charCode >= 48 && charCode <= 57) || // digits
      charCode === 43 || // plus sign (+)
      charCode === 8 || // backspace
      charCode === 46 || // delete
      (charCode >= 37 && charCode <= 40) // arrow keys
    ) {
      // Allow the plus sign (+) only at the beginning
      if (inputValue.length === 0 && charCode === 43) {
        return true;
      }
      // Allow digits after the plus sign (+)
      if (inputValue.length > 0 && inputValue.charAt(0) === '+' && charCode >= 48 && charCode <= 57) {
        return true;
      }
      // Allow other allowed keys
      return true;
    } else {
      return false; // Block all other keys
    }
  }
  

  getEventDetail() {
    this.commonService.eventDetail.subscribe(resp => {
      this.eventDetail = resp;
      if (this.eventDetail?.eventSeatImage) {
        this.showSeatFlag = true;
      }
      else {
        this.showSeatFlag = false;
      }
    });
  }

  checkAttendeeFlag() {
    if (this.attendeecartdData?.length < 0 || !this.attendeecartdData) {
      this.commonService.groupRegistrationFormSubject.subscribe((regResp) => {
        this.registrationFormData = regResp;
        if (this.registrationFormData?.isAttendee) {
          const ownerIsAttendeeData: any = {
            firstName: this.registrationFormData.firstName,
            lastName: this.registrationFormData.lastName,
            email: this.registrationFormData.email,
            mobileNumber: this.registrationFormData.mobileNumber.replace('+61', ''),
            seatnumber: this.registrationFormData.seatnumber,
            isGroupLeader: false,
            jobTitle : this.registrationFormData.jobTitle
          }
          if (this.registrationFormData.customQuestionLists.length) {
            this.registrationFormData.customQuestionLists.forEach((item: any) => {
              const { customQuestionId, response } = item;
              ownerIsAttendeeData[customQuestionId] = response;
            });
          }
          this.spinner.hide();
          this.attendees.push(ownerIsAttendeeData);
        }
      });
    }
  }

  questionDependent() {
    this.eventService.GetCustomLogicQuestionByEventId(this.eventDetailsId).subscribe((QuestionResp: any) => {
      if (QuestionResp.isSuccessful == true) {
        if (QuestionResp?.data?.length > 0) {
          this.questionsList = QuestionResp?.data;
          this.questionType = [];
          this.dynamicQuestion();
          this.allQuestionType = QuestionResp?.data

          // Iterate over each question in jsonData
          QuestionResp?.data.forEach((question: any) => {
            // Add the current question to allQuestions
            this.dynamicQuestions.push(question);
            // Recursively extract dependent questions and add them to allQuestions
            this.dynamicQuestions.push(...this.extractDependentQuestions(question));
          });
        }
        this.isShow = true;
        if (this.attendeecartdData?.length < 0 || !this.attendeecartdData) {
          this.checkAttendeeFlag();
        }
        else {
          this.patchCardForAttendees();
        }
      }
      else {
        this.spinner.hide();
        this.isShow = true;

        this.questionType = [];
        if (this.attendeecartdData?.length < 0 || !this.attendeecartdData) {
          this.checkAttendeeFlag();
        }
        else {
          this.patchCardForAttendees();
        }
      }
    }),
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.messageDetail.message, 'Error');
      };
  }
  // Define a function to recursively extract dependent questions
  extractDependentQuestions(question: any): any[] {
    const result: any[] = [];

    // If the question has dependentQuestionLists
    if (question.dependentQuestionLists && question.dependentQuestionLists.length > 0) {
      // Iterate over dependentQuestionLists
      question.dependentQuestionLists.forEach((dependentQuestion: any) => {
        // Clone the original question and replace its dependentQuestionLists with an empty array
        const clonedQuestion = JSON.parse(JSON.stringify(question));
        clonedQuestion.dependentQuestionLists = [];
        // Add the dependent question to the result
        result.push(dependentQuestion);
        // Recursively extract dependent questions of the current dependent question
        result.push(...this.extractDependentQuestions(dependentQuestion));
      });
    }

    return result;
  }





  dynamicQuestion() {
    this.questionsList.forEach((question: any, index: number) => {
      if (question.dependentOnOtherQue == false) {

        this.questionType.push(question);
        // if (question.choices?.length && question.type.label === 'Multiple choice') {
        //   let tempChoices: any[] = [];
        //   question.choices.forEach((choice: any) => {
        //     let obj = {
        //       choiceName: choice, isShow: true
        //     };
        //     tempChoices.push(obj)
        //   });
        //   this.questionType[index].choices = tempChoices;
        // }
        const validators = question ? [Validators.required] : null;
        let defaultValue;
        if (question.type.label === 'Multiple choice') {

          defaultValue = question.type.label === 'Multiple choice' ? [] : [];
        }
        if (question.type.label === 'Boolean (yes/no)') {

          defaultValue = question.type.label === 'Boolean (yes/no)' ? 'No' : 'No';
        }

        this.groupRegisterEventForm.addControl(
          question.customRegistrationFieldId,
          this.fb.control(defaultValue, validators)
        );
      }
    });
  }

  openDialog(): void {
    this.dialogRef = this._dialog.open(SeatDialogComponent, {
      disableClose: true,
      width: '900px',
      height: '550px'

    })
  }

  close() {
    this.dialogRef.close(true)
  }

  addAttendee(form: FormGroup) {
    if (form.invalid) {
      this.markFormGroupTouched(this.groupRegisterEventForm);
      this.spinner.hide();
      return;
    }
    this.editAttendeeFlag = false;
    this.addAttendeeFlg = true;
    if (this.attendees.length < this.registrationCountLeft) {
      if (this.attendees.length < this.passObjresp.numberOfPassesAvailable) {
        if (this.seat > 0 || this.token) {

          if (this.attendees.length < this.maxiMumGroupSize && this.attendees.length < this.passObjresp.numberOfPassesAvailable) {
            // Adding new attendee
            if (this.groupRegisterEventForm.valid) {
              const attendeeData = this.groupRegisterEventForm.value;
              // Check if there's already a group leader in the attendees
              const hasGroupLeader = this.attendees.some(attendee => attendee.isGroupLeader === true);

              // If the current form is attempting to add another group leader
              if (hasGroupLeader && attendeeData.isGroupLeader) {
                // this.isGrpLeader = true;
                this.toaster.warning('Your group already has a Group Leader.', 'Error');
              } else {
                this.attendees.push(attendeeData);
                this.groupRegisterEventForm.reset();
                this.groupRegisterEventForm.controls['isGroupLeader'].setValue(false);
                this.removeDependentQuestionControls();
                // Set default value for 'Boolean (yes/no)' question type
                this.setDefaultBooleanValue();
                // }
              }
            }
          }
          else {
            this.toaster.error('You can only add ' + this.maxiMumGroupSize + ' attendees in one group registration.', 'Error')
          }

        }
        else {
          this.toaster.error("Seats are not available for this Event.", 'Error')
        }
      }
      else {
        this.toaster.error("Tickets are sold out", 'Error')
      }
    }
    else {
      this.toaster.warning('Event registration limit has been reached ')
    }

  }


  removeDependentQuestionControls() {
    // Filter out the questions that are dependent on other questions
    const dependentQuestions = this.dynamicQuestions.filter((question: any) => question.dependentOnOtherQue === true);

    // Iterate over the dependent questions and remove their controls
    dependentQuestions.forEach((question: any) => {
      const control = this.groupRegisterEventForm.get(question.customRegistrationFieldId);
      if (control) {
        control.setValue(null); // Optional: Set the value to null before removing
        this.groupRegisterEventForm.removeControl(question.customRegistrationFieldId);
      }

      // Find the index of the question in this.questionType
      const indexOfQuestion = this.questionType.findIndex((existingQ: any) => existingQ.customRegistrationFieldId === question.customRegistrationFieldId);

      // Check if the question was found (indexOfQuestion !== -1)
      if (indexOfQuestion !== -1) {
        // Remove the question from this.questionType
        this.questionType.splice(indexOfQuestion, 1);
      }
    });
  }
  setDefaultBooleanValue() {
    // Find the question of type 'Boolean (yes/no)'
    const booleanQuestion = this.questionType.find((question: any) => question.type.label === 'Boolean (yes/no)');

    // If found, set the default value to 'No'
    if (booleanQuestion) {
      this.groupRegisterEventForm.controls[booleanQuestion.customRegistrationFieldId].setValue('No');
    }
  }


  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
  edit(index: number) {
    this.removeDependentQuestionControls();
    this.editAttendeeFlag = true;
    this.addAttendeeFlg = false;
    this.editingIndex = index;
  
    // Add dynamic controls if they don't exist
    Object.keys(this.attendees[index]).forEach((key) => {
      let control = this.groupRegisterEventForm.get(`${key}`);
      if (!control) {
        this.groupRegisterEventForm.addControl(
          key,
          this.fb.control(null)
        );
  
        if (!this.questionType.find(que => que.customRegistrationFieldId == key)) {
          if (this.dynamicQuestions.find(que => que.customRegistrationFieldId == key)) {
            let tempQuestion = this.dynamicQuestions.find(que => que.customRegistrationFieldId == key);
            this.questionType.push(JSON.parse(JSON.stringify(tempQuestion)));
          }
        }
      }
    });
  
    // Set values for existing controls
    this.groupRegisterEventForm.patchValue(this.attendees[index]);
  
    // Set values for dynamic controls from attendeecartdData if available
    if (this.attendeecartdData) {
      const attendeeData = { ...this.attendeecartdData[index] }; // Create a copy of the attendee data
      if (attendeeData.mobileNumber && attendeeData.mobileNumber.startsWith('+61')) {
          attendeeData.mobileNumber = attendeeData.mobileNumber.replace('+61', ''); // Remove '+61' prefix
      }
      this.groupRegisterEventForm.patchValue(attendeeData);
  }
  }
  

  updateAttendee(i: any , form: FormGroup) {
    if (form.invalid) {
      this.markFormGroupTouched(this.groupRegisterEventForm);
      this.spinner.hide();
      return;
    }

    if (this.editingIndex !== null) {
      const attendeeData = this.groupRegisterEventForm.value;
      // Convert new email to lowercase for case-insensitive comparison
      const newEmailLowercase = attendeeData.email.toLowerCase();
      // Check if there's already a group leader in the attendees excluding the current one being edited
      const hasGroupLeader = this.attendees
        .filter((_, index) => index !== this.editingIndex) // Exclude the currently edited attendee
        .some(attendee => attendee.isGroupLeader === true);

      if (hasGroupLeader && attendeeData.isGroupLeader) {
        this.toaster.warning('Your group already has a Group Leader.', 'Error');
      } else {
        this.attendees[this.editingIndex] = this.groupRegisterEventForm.value;
        this.editingIndex = null;
        this.groupRegisterEventForm.reset();
        this.groupRegisterEventForm.controls['isGroupLeader'].setValue(false);
        this.removeDependentQuestionControls();
        this.editAttendeeFlag = false;
        this.addAttendeeFlg = true;
        this.setDefaultBooleanValue();
      }
      // }
    }
  }
  delete(index: number) {
    this.attendees.splice(index, 1);
  }


  checkDependent(x: any, data: any) {
    if (data?.dependentQuestionLists?.length > 0) {
      let value = [];
      if (data.type.label === 'Multiple choice') {
        for (let index = 0; index < x.value.length; index++) {
          const currentValue = x.value[index];

          // Check if currentValue is a string before using toLowerCase
          if (typeof currentValue === 'string') {
            data.dependentQuestionLists.forEach((matchData: any) => {
              let checkRepeatQuesMulti;
              if (matchData.showOptionValue.toLowerCase() == currentValue.toLowerCase()) {
                for (let i = 0; i = value.length; i++) {
                  checkRepeatQuesMulti = this.questionType.find((questionRepeat: any) => questionRepeat.customRegistrationQueId == value[i].customRegistrationQueId)
                }
                if (!checkRepeatQuesMulti) {

                  value.push(matchData);
                }
              }
              else {
                this.removeDependentQuestions(x.value, data.dependentQuestionLists, data);
              }
            })
          }
        }
        let checkRepeatQues;
        for (let i = 0; i < value.length; i++) {
          checkRepeatQues = this.questionType.find((questionRepeat: any) => questionRepeat.customRegistrationQueId == value[i].customRegistrationQueId)
        }
        if (!checkRepeatQues) {
          value?.forEach((question: any, index: number) => {
            this.questionType.push(question);
            const validators = question ? [Validators.required] : null;
            let defaultValue;
            if (question.type.label === 'Multiple choice') {

              defaultValue = question.type.label === 'Multiple choice' ? [] : '';
            }
            if (question.type.label === 'Boolean (yes/no)') {

              defaultValue = question.type.label === 'Boolean (yes/no)' ? 'No' : 'No';
            }
            this.groupRegisterEventForm.addControl(
              question.customRegistrationFieldId,
              this.fb.control(defaultValue, validators)
            )
          })
        }
        if (x.value.length == 0) {

          this.removeDependentQuestions(x.value, data.dependentQuestionLists, data);
        }

      }
      else {


        value = data.dependentQuestionLists.filter((data: any) => data.showOptionValue.toLowerCase() == x.value.toLowerCase());

        //if (x.value.toLowerCase() === data.dependentQuestionLists[0].showOptionValue.toLowerCase()) {
        value?.forEach((question: any, index: number) => {
          this.questionType.push(question);
        
          const validators = question ? [Validators.required] : null;
          let defaultValue;
          if (question.type.label === 'Multiple choice') {

            defaultValue = question.type.label === 'Multiple choice' ? [] : '';
          }
          if (question.type.label === 'Boolean (yes/no)') {

            defaultValue = question.type.label === 'Boolean (yes/no)' ? 'No' : 'No';
          }
          this.groupRegisterEventForm.addControl(
            question.customRegistrationFieldId,
            this.fb.control(defaultValue, validators)
          );


        })
        if (value.length >= 1) {
          this.removeDependentQuestions(x.value, data.dependentQuestionLists, data);
        }
        else {
          data?.dependentQuestionLists && this.removeAllDependentQuestions(data.dependentQuestionLists);
        }
      }
    }
  }

  removeAllDependentQuestions(questionList: any[]) {
    questionList.forEach((question: any, idx: number) => {
      // Check if the current question has dependent questions
      if (question.dependentQuestionLists && question.dependentQuestionLists.length > 0) {
        // Recursively remove dependent questions
        this.removeAllDependentQuestions(question.dependentQuestionLists);
      }

      // Check if the current question is present in the questionType array
      const questionIndex = this.questionType.findIndex(x => x.customRegistrationFieldId === question.customRegistrationFieldId);
      if (questionIndex > -1) {
        // Remove the control from the form
        this.groupRegisterEventForm.removeControl(question.customRegistrationFieldId);

        // Remove the question from the questionType array
        this.questionType.splice(questionIndex, 1);
      }
    });

  }

  removeDependentQuestions(selectedValue: any, questionList: any[], selectedQuestion: any) {
    questionList.forEach((question: any, idx: number) => {
      // Check if the current question has dependent questions
      if (question.dependentQuestionLists && question.dependentQuestionLists.length > 0) {
        // Recursively remove dependent questions
        this.removeDependentQuestions(selectedValue, question.dependentQuestionLists, selectedQuestion);
      }

      if (selectedQuestion.type.label === 'Multiple choice') {
        for (let index = 0; index < selectedValue.length; index++) {
          if (
            question.showOptionValue &&
            !selectedValue.some(value => value.toLowerCase() === question.showOptionValue.toLowerCase())
          ) {
            // Remove the control from the form
            if (this.groupRegisterEventForm.get(question.customRegistrationFieldId)) {
              this.groupRegisterEventForm.removeControl(question.customRegistrationFieldId);
            }

            // Remove the question from the questionType array
            const questionIndex = this.questionType.findIndex(q => q.customRegistrationFieldId === question.customRegistrationFieldId);
            if (questionIndex !== -1) {
              this.questionType.splice(questionIndex, 1);
            }
          }
        }

        if (selectedValue.length == 0) {
          if (this.groupRegisterEventForm.get(question.customRegistrationFieldId)) {
            this.groupRegisterEventForm.removeControl(question.customRegistrationFieldId);
          }

          // Remove the question from the questionType array
          const questionIndex = this.questionType.findIndex(q => q.customRegistrationFieldId === question.customRegistrationFieldId);
          if (questionIndex !== -1) {
            this.questionType.splice(questionIndex, 1);
          }
        }

      }
      else {
        // Check if the current question's showOptionValue does not match the selectedValue
        if (question.showOptionValue && question.showOptionValue.toLowerCase() !== selectedValue.toLowerCase()) {
          // Remove the control from the form
          if (this.groupRegisterEventForm.get(question.customRegistrationFieldId)) {
            this.groupRegisterEventForm.removeControl(question.customRegistrationFieldId);
          }

          // Remove the question from the questionType array
          const questionIndex = this.questionType.findIndex(q => q.customRegistrationFieldId === question.customRegistrationFieldId);
          if (questionIndex !== -1) {
            this.questionType.splice(questionIndex, 1);
          }
        }
      }
    });
  }

  submit() {
    const hasGroupLeader = this.attendees.some(attendee => attendee.isGroupLeader === true);
    if (hasGroupLeader) {

      let tempAttendeeDataForBackButton = JSON.parse(JSON.stringify(this.attendees));

      tempAttendeeDataForBackButton.forEach((x: any) => {
        x.mobileNumber = `+61${x.mobileNumber}`;
        let keyArray = Object.keys(x);
        let tempAttendee: any = [];
        keyArray.forEach((y: any) => {
          let exists = this.dynamicQuestions?.find((data: any) => data.customRegistrationFieldId === y);
          if (exists) {
            let id = exists.customRegistrationFieldId
            let valueToAssignBackObj = x[`${y}`];

            let backObj = {
              customQuestionId: id,
              response: valueToAssignBackObj
            }
            tempAttendee.push(backObj);
            delete x[`${y}`];
          }
        });
        x.attendeeCustomQuestionLists = tempAttendee;
      });
      this.commonService.attendeecartdDatasubmit.next(tempAttendeeDataForBackButton);
      let backObject = {
        firstName: this.registrationFormData.firstName,
        lastName: this.registrationFormData.lastName,
        email: this.registrationFormData.email,
        mobileNumber: this.registrationFormData.mobileNumber,
        eventId: this.eventDetailsId,
        passId: this.registrationFormData.passId,
        sessionLists: this.registrationFormData.sessionLists,
        seatnumber: this.registrationFormData.seatnumber,
        applyPromoCode: this.registrationFormData.applyPromoCode,
        applyPromoCodeId: this.registrationFormData.applyPromoCodeId,
        attendeeLists: tempAttendeeDataForBackButton,
        isGroupRegistration: true,
        registrationMode: {
          value: this.registrationFormData.registrationMode.value,
          label: this.registrationFormData.registrationMode.label
        }
      }


      this.attendees.forEach(x => {
        x.mobileNumber = `+61${x.mobileNumber}`;
        let keyArray = Object.keys(x);
        let attendeeCustomQuestionLists: any = [];
        keyArray.forEach((y: any) => {
          let exists = this.dynamicQuestions?.find((data: any) => data.customRegistrationFieldId === y);
          if (exists) {
            let id = exists.customRegistrationQueId
            let valueToAssign = x[`${y}`];
            // If the value is an array, convert it to a comma-separated string
            if (Array.isArray(valueToAssign)) {
              valueToAssign = valueToAssign.join(', ');
            }

            let obj = {
              customQuestionId: id,
              response: valueToAssign
            };

            attendeeCustomQuestionLists.push(obj);
            delete x[`${y}`];
          }
        });
        x.attendeeCustomQuestionLists = attendeeCustomQuestionLists;
      });
      this.groupRegisterEventForm.reset();
      this.groupRegisterEventForm.controls['isGroupLeader'].setValue(false);
      const formData = this.groupRegisterEventForm.value;
      let object = {
        firstName: this.registrationFormData.firstName,
        lastName: this.registrationFormData.lastName,
        email: this.registrationFormData.email,
        mobileNumber:  this.registrationFormData.mobileNumber,
        eventId: this.eventDetailsId,
        passId: this.registrationFormData.passId,
        sessionLists: this.registrationFormData.sessionLists,
        seatnumber: this.registrationFormData.seatnumber,
        applyPromoCode: this.registrationFormData.applyPromoCode,
        applyPromoCodeId: this.registrationFormData.applyPromoCodeId,
        jobTitle: this.registrationFormData.jobTitle,
        attendeeLists: this.attendees,
        isGroupRegistration: true,
        registrationMode: {
          value: this.registrationFormData.registrationMode.value,
          label: this.registrationFormData.registrationMode.label
        }

      }
      this.commonService.registrationFormSubject.next(object);
      this.router.navigate(['/payment-info/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat, Type: this.data, Token: this.token } });

    }
    else {
      this.toaster.warning('At least one attendee should be a Group leader.', 'Error');
    }
  }

  returnControl(controlName: any) {
    return this.groupRegisterEventForm.get(`${controlName}`);
  }
  back() {
    this.commonService.attendeecartdDatasubmit.next(null);
    this.router.navigate(['/event-registration/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
  }



}