<div class="d-flex justify-content-between align-items-center">
  <ngx-spinner bdColor="rgba(23, 1, 51, 0.9)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
    <p style="color: white"> please wait... </p>
  </ngx-spinner>
</div>
<div class="container bradcum-max-width" *ngIf="!data">
  <div class="row py-2 d-flex justify-content-center">
    <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
      <ol class="breadcrumb m-0">
        <li class="breadcrumb-item"><a href="#">Business NSW</a></li>
        <li class="breadcrumb-item"><a routerLink="">Event</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/event-details', eventDetailsId]">Event Details</a></li>
        <li class="breadcrumb-item"> <a [routerLink]="['/event-registration', eventDetailsId]"
            [queryParams]="{ value: value, seat: seat }">
            Event Registration
          </a> </li>
        <li *ngIf="value=='Group'" class="breadcrumb-item"> <a [routerLink]="['/group-attendee/'+ eventDetailsId]"
            [queryParams]="{ value: value, seat: seat }">
            Group Attendee
          </a> </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/payment-info/' + eventDetailsId]" [queryParams]="{ value: value, seat: seat }">
            Payment Information
          </a>
        </li>
        <li class="breadcrumb-item">
          Stripe Payment
        </li>
      </ol>
    </nav>
  </div>
</div>

<div class="p-4">
  <div class="row ">
    <div class="col-3 my-auto"></div>
    <div class="col-6 my-auto ">
      <form novalidate (ngSubmit)="OnSubmitMakePayment()" [formGroup]="paymentForm">
        <div class="ml-auto mr-auto text-center card py-3 p-4">
          <mat-form-field class="example-full-width ">
            <span matPrefix>
              <mat-icon matPrefix>account_box</mat-icon>
            </span>
            <mat-label> Card Holder Name</mat-label>
            <input type="text" matInput placeholder="" formControlName="name" value="">
          </mat-form-field>
          <mat-form-field class="example-full-width ">
            <span matPrefix class="astrict">
              <mat-icon matPrefix> email</mat-icon>
            </span>
            <mat-label> Email </mat-label>
            <input matInput placeholder="" formControlName="email" value="">
          </mat-form-field>
          <div class="col-12 mb-2">
            <div id="card-element" class="field"></div>
            <div id="last-four-digits"></div>
          </div>
          <button mat-raised-button type="submit" class="text-white p-1 my-3 btn-pay">
            Pay {{paymentForm.value.amount | currency: currencyAUD : 'symbol'}}
          </button>
        </div>
      </form>
      <div class="mt-4">
        <button type="button" class="btn-cancel rounded-xl btn shadow-lg btn-green fw-bold   " (click)="back()"
          [matTooltip]="'Back'" *ngIf="!data">
          <mat-icon class="align-middle">arrow_back</mat-icon>
          Back
        </button>
      </div>
    </div>
  </div>
</div>