export class API_Constants {
  // public static readonly baseURL = 'https://localhost:44338/';

  //DEV

  //Leave it empty so that it calls the host URL only
  public static readonly baseURL = "https://clientapi-dev.solzit.com/";
  public static readonly updatePaymentDetails = 'Invoice/UpdateInvoice';
  public static readonly getPaymentDetails = 'api/V1/Payment/GetPaymentDetails';
  public static readonly getPaymentDetailsForAdHoc = 'api/V1/Payment/GetPaymentDetailsAdHoc/';
  //  public static readonly stripeKey="sk_test_51OG0c9K8zdW8voHF7EJ5hDcipKKOyzxDVFmLxUJkK4vFLTg5yABNaOrrHaahfortdWwugyuKqij2HAAbd5MuUIj800YFCuHApu";  //Pradeep Stripe Account
  public static readonly stripeKey = "sk_test_51O2oRuBDvrCiJmRu86pvjFD0zmoMsAgLLglmqi1imNHcxz1IV4iTCoy30EkIsAyWZABhp4MQb5GC0XwFyx7h3wA800HMBwsDc0";      //Sovit Client Stripe Account
  public static readonly customerURL = "https://api.stripe.com/v1/customers";
  public static readonly paymentIntentURL = "https://api.stripe.com/v1/payment_intents";
  public static readonly paymentMethodURL = "https://api.stripe.com/v1/payment_methods";
  public static readonly revertPayment = ' https://api.stripe.com/v1/refunds';

}
