<div class="d-flex justify-content-between align-items-center">
    <ngx-spinner bdColor="rgba(23, 1, 51, 0.9)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
        <p style="color: white"> Please Wait... </p>
    </ngx-spinner>
</div>
<div class="container bradcum-max-width" *ngIf="!data">
    <div class="row py-2 d-flex justify-content-center">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="#">Business NSW</a></li>
                <li class="breadcrumb-item"><a routerLink="">Event</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/event-details', eventDetailsId]">Event Details</a></li>
                <li class="breadcrumb-item"> <a [routerLink]="['/event-registration', eventDetailsId]"
                        [queryParams]="{ value: value, seat: seat }">
                        Event Registration
                    </a> </li>
                <li *ngIf="value=='Group'" class="breadcrumb-item"> <a
                        [routerLink]="['/group-attendee/'+eventDetailsId]" [queryParams]="{ value: value, seat: seat }">
                        Group Attendee
                    </a> </li>
                <li class="breadcrumb-item">
                    Billing Information
                </li>
            </ol>
        </nav>
    </div>
</div>

<div class=" card-bg-color my-4 p-6">
    <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
            <div class="card card  rounded-xl shadow-lg p-4 my-4 ">
                <div class=" sm:flex-rol lg:flex-row md:flex-row  text-lg">

                    <p class="text-center p-1 font-heading fw-bold rounded-full">Billing Information </p>
                    <p class="text-center font-heading fw-bold rounded-full">{{eventName}}</p>
                </div>
                <div class="fs-6">Ticket Type :
                    <p class="text-end">{{passName}}</p>
                </div>
                <div class="fs-6">Ticket Price :
                    <div class="text-end">
                        {{ selectPassPrice | currency: currencyAUD : 'symbol' }}
                    </div>
                </div>

                <!-- CRM Ticket price over ride -->
                <div class="row" *ngIf="data">
                    <br>
                    <div class="col-4 p-0 m-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="">Overwrite Ticket Price :</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                    </div>
                    <div class="col-2">
                        <input type="text" class="form-control" [(ngModel)]="overRideTicket"
                            (keypress)="numberOnly($event)" (keyup)="ticketOverRide()">
                    </div>
                </div>
                <br>
                <div class="fs-6">No. of attendee :
                    <p class="text-end">* {{attendeeLength}}</p>
                </div>
                <div class="fs-6">Subtotal
                    <div class="text-end"> {{subTotal | currency: currencyAUD : 'symbol'}}</div>
                </div>
                <div>
                    <br>
                    <div class="d-flex mb-3">
                        <div>
                            <input type="text" class="form-control" placeholder="Enter promo code"
                                [(ngModel)]="promocode" aria-label="Recipient's username"
                                aria-describedby="basic-addon2" maxlength="15">
                        </div>

                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary btncolor text-white" type="button"
                                (click)="promoCodeApply()" [disabled]="!promocode">Apply</button>
                            <button *ngIf="promocode" class="btn btn-outline-secondary text-white btncolorclear ml-2"
                                type="button" (click)="clearPromoCode()">Clear</button>
                        </div>
                    </div>
                </div>
                <div class="flex mb-4" *ngIf="discountAmountInRupees !== undefined  ">
                    <div class="col-8">
                        <div>Discount</div>
                    </div>
                    <div class="col-4 text-end">

                        <p>- {{discountAmountInRupees}}</p>
                    </div>
                </div>

                <hr class="mb-2">
                <div class=" ">
                    
                    <mat-checkbox [(ngModel)]="checked" (click)="checkBoxBtn( $event)">
                    </mat-checkbox>
                  <span> By accepting attendance at this Business NSW event, I acknowledge I have read Business NSW’s
                    <a class="   paymentPolicy box-primary" (click)="openDialog()">
                        <!-- <mat-icon class="align-middle">link</mat-icon>  -->
                         Privacy Policy
                    </a> and consent to receive updates and information from Business NSW.</span>
                    
                    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>

                </div>
                <!-- <div class="row d-flex ">
                    <div class="col-6 text-end p-2 my-2"></div>
                    <div class="col-6 text-end p-2 my-2">

                        <button type="button" class="btn-warning rounded-xl btn btn-yellow fw-bold  "
                            (click)="openDialog()">
                            <mat-icon class="align-middle">airline_seat_recline_normal</mat-icon>
                            Policy</button>
                    </div>
                </div> -->
                <div class="row mt-2">
                    <div class="col-6 fw-bold">Total</div>
                    <div class="col-6 text-end">{{totalPrice | currency: currencyAUD : 'symbol'}}</div>
                </div>
                <div class="d-flex flex-column flex-sm-row mt-4">
                    <div class="col-12 col-sm-6 mb-2 mb-sm-0">
                        <button *ngIf="!data" type="button"
                            class="btn-cancel rounded-xl btn shadow-lg btn-green fw-bold   " (click)="back()"
                            [matTooltip]="'Back'">
                            <mat-icon class="align-middle">arrow_back</mat-icon>
                            Back
                        </button>
                    </div>
                    <div class="col-12 col-sm-6 text-center text-sm-end">

                        <button type="button" class="rounded-xl btn shadow-lg btn-green fw-bold" (click)="submitPay()"
                            [matTooltip]="'Next'">
                            Next <mat-icon class="align-middle">arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</div>