import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/services/common.service';
import { EventsService } from 'src/services/events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  eventTypes: any[] = [];
  whislistDataLength: any = 0;
  data: any;
  token: any;
  constructor(public commonService: CommonService, private route: ActivatedRoute,private eventService: EventsService,
    private router: Router) {
    this.commonService.wishlistData.subscribe((wishlistDetails: any) => {
      if (wishlistDetails?.length > -1) {

        this.whislistDataLength = wishlistDetails.length
      }
    })
    this.commonService.crmUrlData.subscribe((x: any) => {
      this.data = x?.data;
      this.token = x?.token;
    })
    if(this.data){
      this.eventService.GetAuthentication(this.token).subscribe((data:any)=>{
        if(data.isSuccessful == true){
          this.router.navigate(['/unauthorized']);
        }
      })
    }
  }

  ngOnInit() {
    let header: any;
    header = document.querySelector('.bc-header__outer-container');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        header?.classList.add('scrolled');
      } else {
        header?.classList.remove('scrolled');
      }
    });
    let header1: any;
    header1 = document.querySelector('.bc-header');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        header1?.classList.add('scrolled');
      } else {
        header1?.classList.remove('scrolled');
      }
    });
    let header2: any;
    header2 = document.querySelector('.bc-searchbar-searchIcon');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        header2?.classList.add('scrolled');
      } else {
        header2?.classList.remove('scrolled');
      }
    });
  }

  getAllEventsTypes() {
    let url = './assets/eventTypes.json'
    this.commonService.get(url).subscribe((data: any) => {
      this.eventTypes = data;
    })
  }
  Wishlist() {
    this.router.navigate(['/wishList'])
  }
}
