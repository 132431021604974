import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/services/common.service';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.scss']
})
export class WishListComponent {
  wishlistDetails: any;
  constructor(
    private commonServices: CommonService,
    private router: Router
  ) {
    this.commonServices.wishlistData.subscribe((wishlistDetails: any) => {
      this.wishlistDetails = wishlistDetails
    })
  }

  ngOnInIt() {

  }

  edit(data: any) {
    data.eventId
    this.router.navigate(['/event-details/' + data.eventId])
  }
  removeWishlist(index: number): void {
    this.wishlistDetails.splice(index, 1);
    this.commonServices.wishlistData.next(this.wishlistDetails)
  }

  showMoreDetails(id: any) {
    //localStorage.setItem('eventId', id);
    this.router.navigate(['event-details/' + id],);
  }
}
