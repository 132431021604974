import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/shared/shared.module';
import { CommonService } from 'src/services/common.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import {ToastrModule, } from 'ngx-toastr';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    NgxSpinnerModule.forRoot(),
    ToastrModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripeKEY),
  ],
  providers: [CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
