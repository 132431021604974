<div class="d-flex justify-content-between align-items-center">
    <ngx-spinner bdColor="rgba(23, 1, 51, 0.9)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
        <p style="color: white"> Please Wait... </p>
    </ngx-spinner>
</div>

<div class=" card-bg-color my-4 p-6">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
            <div class="card card  rounded-xl shadow-lg p-4 my-4 ">
                <form [formGroup]="attendeesForm">
                    <p class="text-center p-1 font-heading">Group Attendees Information</p>
                    <p class="text-center">Note : At least One Attendee Should be present in Group</p>
                    <div class="row">

                        <div class="col-6">
                            <!-- <button type="button" (click)="addAttendeeForm()"
                                class="btn-primary rounded-xl btn fw-bold">
                                <mat-icon>add_circle</mat-icon>Add Attendee</button> -->
                            <div class="flex sm:flex-rol lg:flex-row md:flex-row ml-auto text-lg">
                                <button type="button" class="btn-primary rounded-xl btn fw-bold"
                                    (click)="addAttendeeForm()" [matTooltip]="'Add Attendee'">
                                    <mat-icon class="align-middle">add</mat-icon> Add Attendee
                                </button>
                            </div>
                        </div>
                        <div class="col-6 counters text-right my-2">
                            <!-- Total Attendees : {{ attendeesCount }} -->
                            <span>
                                <span
                                    class="mx-2 inline-flex items-center font-bold text-sm px-2 py-0.5 rounded-full tracking-wide bg-red-100 text-red-800">
                                    <span class="pr-0.5 leading-relaxed whitespace-nowrap">Total Attendees : {{
                                        attendeesCount }}</span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <br>

                    <div formArrayName="attendees">
                        <div *ngFor="let attendee of attendees.controls; let i = index" class="attendee">
                            <div [formGroupName]="i" class="attendee-fields">
                                <mat-radio-group>
                                    <mat-radio-button value="true" formControlName="isGroupLeader"
                                        class="text-base h-6 w-6"></mat-radio-button>
                                </mat-radio-group>

                                <!-- <mat-checkbox formControlName="isGroupLeader" class="text-base h-6 w-6" matTooltip="Is Group
                                    Leader?">Is Group
                                    Leader?
                                </mat-checkbox> -->


                                <mat-form-field class="example-full-width">
                                    <mat-label> First Name</mat-label>
                                    <span matPrefix>
                                        <mat-icon matPrefix>account_box</mat-icon>
                                    </span>
                                    <input formControlName="firstName" matInput placeholder="" value="">
                                    <mat-error *ngIf="attendee.get('firstName')?.touched && attendee.get('firstName')?.invalid
                                        ||attendee.get('firstName')?.errors">
                                        <small class="error" *ngIf="attendee.get('firstName')?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                        <small class="error" *ngIf="attendee.get('firstName')?.hasError('pattern')">
                                            Please Enter Valid Name.
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width">
                                    <mat-label> Last Name</mat-label>
                                    <span matPrefix>
                                        <mat-icon matPrefix>account_box</mat-icon>
                                    </span>
                                    <input formControlName="lastName" matInput placeholder="" value="">
                                    <mat-error *ngIf="attendee.get('lastName')?.touched && attendee.get('lastName')?.invalid
                                        ||attendee.get('lastName')?.errors">
                                        <small class="error" *ngIf="attendee.get('lastName')?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                        <small class="error" *ngIf="attendee.get('lastName')?.hasError('pattern')">
                                            Please Enter Valid Name.
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width">
                                    <mat-label> Mobile Number</mat-label>
                                    <span matPrefix>
                                        <mat-icon matPrefix>phone</mat-icon>
                                    </span>
                                    <input formControlName="mobileNumber" matInput placeholder="" value="">
                                    <mat-error *ngIf="attendee.get('mobileNumber')?.touched && attendee.get('mobileNumber')?.invalid
                                        ||attendee.get('mobileNumber')?.errors">
                                        <small class="error" *ngIf="attendee.get('mobileNumber')?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                        <small class="error" *ngIf="attendee.get('mobileNumber')?.hasError('pattern')">
                                            Please Enter Valid Mobile Number.
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width">
                                    <mat-label> Email</mat-label>
                                    <span matPrefix>
                                        <mat-icon matPrefix>email</mat-icon>
                                    </span>
                                    <input formControlName="email" matInput placeholder="" value="">
                                    <mat-error *ngIf="attendee.get('email')?.touched && attendee.get('email')?.invalid
                                        ||attendee.get('email')?.errors">
                                        <small class="error" *ngIf="attendee.get('email')?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                        <small class="error" *ngIf="attendee.get('email')?.hasError('pattern')">
                                            Please Enter Valid Email.
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <br>

                                <span class="flex m-auto sm:flex-row lg:flex-row md:flex-row ml-auto text-lg">
                                    <button type="button" class="bg-red-100 p-2 text-red-800 rounded-full"
                                        (click)="removeAttendee(i)" [matTooltip]="'Remove Attendee'">
                                        <mat-icon class="align-middle">delete</mat-icon>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="text-right" *ngIf="attendeesCount > 0">
                        <span class="m-auto sm:flex-row lg:flex-row md:flex-row ml-auto text-lg">
                            <button type="button" class="btn rounded-xl btn shadow-lg btn-green fw-bold"
                                (click)="next()">Next
                                <mat-icon class="align-middle">arrow_right_alt</mat-icon>
                            </button>
                        </span>
                    </div>
                </form>

            </div>
        </div>
        <div class="col-1"></div>

    </div>
</div>