<div class="container ">
<h2 mat-dialog-title class="fw-bold ">Seating Layout</h2>
<mat-dialog-content class="mat-typography p-0 m-0">
  <div class=" p-0  m-0">
    <div class=" rounded-xl mx-4" >
        <!-- <img *ngIf="!seatImage" src="../../../assets/images/SittingImg.png" class="settingImage" alt=""> -->
        <img *ngIf="seatImage" src="data:image/jpeg;base64,{{seatImage}}" class="" alt="settingImage">
    </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mx-4">
    <!-- <button mat-button class="btn rounded-xl btn shadow-lg btn-cancel  fw-bold  " (click)="viewFullScreen()">View Full</button> -->
  <button mat-button mat-button mat-dialog-close class="btn rounded-xl btn shadow-lg btn-cancel  fw-bold  "  >Close</button>
</mat-dialog-actions> 
</div>