<div class="d-flex justify-content-between align-items-center">
    <ngx-spinner bdColor="rgba(23, 1, 51, 0.9)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
        <p style="color: white"> Please Wait... </p>
    </ngx-spinner>
</div>
<div class="container bradcum-max-width" *ngIf="!data">
    <div class="row py-2 d-flex justify-content-center">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="#">Business NSW</a></li>
                <li class="breadcrumb-item"><a routerLink="">Event</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/event-details', eventDetailsId]">Event Details</a></li>
                <li class="breadcrumb-item">
                    Event Registration
                </li>
            </ol>
        </nav>
    </div>
</div>
<div class=" card-bg-color my-4 p-6">
    <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
            <div class="card card  rounded-xl shadow-lg p-4 my-4 " *ngIf="isShow">
                <form [formGroup]="registerEventForm" class="example-form">
                    <p class="text-center p-1 font-heading section-title">{{value}} Registration for Event</p>

                    <div class="row ">
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix>account_box</mat-icon>
                            </span>
                            <mat-label> First Name</mat-label>
                            <input formControlName="firstName" matInput placeholder="Enter First Name" value="">

                            <mat-error *ngIf="registerEventForm.controls['firstName'].touched &&registerEventForm.controls['firstName'].invalid
                                    || registerEventForm.controls['firstName'].errors">
                                <small *ngIf="registerEventForm.controls['firstName'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="registerEventForm.controls['firstName'].hasError('pattern')">
                                    Enter valid First Name.
                                </small>
                            </mat-error>

                        </mat-form-field>
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix>account_box</mat-icon>
                            </span>
                            <mat-label> Last Name</mat-label>
                            <input matInput placeholder="Enter Last Name" formControlName="lastName" value="">
                            <mat-error *ngIf="registerEventForm.controls['lastName'].touched &&registerEventForm.controls['lastName'].invalid
                                    || registerEventForm.controls['lastName'].errors">
                                <small *ngIf="registerEventForm.controls['lastName'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="registerEventForm.controls['lastName'].hasError('pattern')">
                                    Enter valid Last Name.
                                </small>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix>email</mat-icon>
                            </span>
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Enter Email" formControlName="email" value="">
                            <mat-error *ngIf="registerEventForm.controls['email'].touched &&registerEventForm.controls['email'].invalid
                                    || registerEventForm.controls['email'].errors">
                                <small *ngIf="registerEventForm.controls['email'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="registerEventForm.controls['email'].hasError('pattern')">
                                    Enter valid Email.
                                </small>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix>phone</mat-icon>
                            </span>
                            <mat-label> Mobile Number</mat-label>
                            <input type="text" matInput placeholder="Enter Contact Number"
                                formControlName="mobileNumber" value="" (keypress)="numberOnly($event)">
                            <mat-error *ngIf="registerEventForm.controls['mobileNumber'].touched &&registerEventForm.controls['mobileNumber'].invalid
                            || registerEventForm.controls['mobileNumber'].errors">
                                <small *ngIf="registerEventForm.controls['mobileNumber'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="registerEventForm.controls['mobileNumber'].hasError('pattern')">
                                    Enter valid Mobile Number.
                                </small>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix>domain</mat-icon>
                            </span>
                            <mat-label>Company Name</mat-label>
                            <input matInput placeholder="Enter Company Name" formControlName="companyName" value="">
                            <mat-error *ngIf="registerEventForm.controls['companyName'].touched &&registerEventForm.controls['companyName'].invalid
                                    || registerEventForm.controls['companyName'].errors">
                                <small *ngIf="registerEventForm.controls['companyName'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <!-- <small *ngIf="registerEventForm.controls['email'].hasError('pattern')">
                                    Enter valid Email.
                                </small> -->
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2 px-2  mb-1">
                            <span matPrefix>
                                <mat-icon matPrefix> subtitles</mat-icon>
                            </span>
                            <mat-label> Job title</mat-label>
                            <input type="text" matInput placeholder="Enter Job title"
                                formControlName="jobTitle" value="" >
                            <mat-error *ngIf="registerEventForm.controls['jobTitle'].touched &&registerEventForm.controls['jobTitle'].invalid
                            || registerEventForm.controls['jobTitle'].errors">
                                <small *ngIf="registerEventForm.controls['jobTitle'].hasError('required')">
                                    Please fill out this field!
                                </small>
                                <small *ngIf="registerEventForm.controls['mobileNumber'].hasError('pattern')">
                                    Enter valid Mobile Number.
                                </small>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row mb-2" *ngIf="value==='Group'">
                        <mat-slide-toggle class="custom-toggle" formControlName="isAttendee"
                            (change)="onSlideToggleChange($event)">Are you attending this
                            event?</mat-slide-toggle>
                    </div>
                    <div *ngIf="eventDetailsData?.eventType?.value == 3" class="d-flex">
                        <div class="pt-2">
                            <h2>Select the Desired Event Mode</h2>
                        </div>
                        <div class="ms-5">
                            <mat-button-toggle-group #group="matButtonToggleGroup" class=" ml-3"
                                formControlName="registrationMode">
                                <mat-button-toggle
                                    [class]="(registerEventForm.value.registrationMode == 1) ?'w-100 h-85 text-white ':'w-100 h-85 '"
                                    [style.background-color]="(registerEventForm.value.registrationMode == 1) ? 'rgb(255,64,129)' : '#fff'"
                                    value="1" name="Online" (change)="onChangeEventMode($event)">
                                    <mat-icon matPrefix class="align-middle">desktop_windows</mat-icon>
                                    Online
                                </mat-button-toggle>
                                <mat-button-toggle
                                    [class]="(registerEventForm.value.registrationMode == 2) ?'w-100 h-85  text-white':'w-100  h-85'"
                                    [style.background-color]="(registerEventForm.value.registrationMode == 2) ? 'rgb(255,64,129)' : '#fff'"
                                    value="2" name="Offline" (change)="onChangeEventMode($event)">
                                    <mat-icon matPrefix class="align-middle">desktop_access_disabled</mat-icon>
                                    In-Person
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <!-- {{showSeatFlag}} and {{eventDetailsData?.eventType?.value}} -->
                    <!-- <div *ngIf="showSeatFlag && eventDetailsData?.eventType?.value == 2">
                        <div class="row d-flex"
                            *ngIf="registerEventForm.get('isAttendee')?.value  == true || value==='Single'">
                            <mat-form-field class="example-full-width w-full sm: md:w-1/2 lg:w-1/2">
                                <span matPrefix>
                                    <mat-icon matPrefix>event_seat</mat-icon>
                                </span>
                                <mat-label> Enter seat number</mat-label>
                                <input type="text" formControlName="seatnumber" matInput
                                    placeholder="e.g. Table1 - 1, 2, 3" value="">
                            </mat-form-field>
                            <div class="col-6 text-end p-2 my-2">

                                <button type="button" class="btn-warning rounded-xl btn btn-yellow fw-bold  "
                                    (click)="openDialog()" [matTooltip]="'preferred seat'">
                                    <mat-icon class="align-middle">airline_seat_recline_normal</mat-icon>
                                    Preferred seat</button>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <mat-form-field class="example-full-width ">
                            <span matPrefix>
                                <mat-icon matPrefix>receipt</mat-icon>
                            </span>
                            <mat-label> Select Ticket</mat-label>
                            <mat-select formControlName="passId"  (selectionChange)="passIdOfPasses($event)">
                                <mat-option *ngFor="let pass of selectedPassList" [value]="pass.passId"
                                    >
                                    {{pass.passName}}&nbsp;{{pass.passPrice | currency: currencyAUD : 'symbol' }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="registerEventForm.controls['passId'].touched &&registerEventForm.controls['passId'].invalid
                                    || registerEventForm.controls['passId'].errors">
                                <small *ngIf="registerEventForm.controls['passId'].hasError('required')">
                                    Please fill out this field!
                                </small>
                            </mat-error>
                        </mat-form-field>
                        <div [hidden]="true">
                            <mat-form-field class="example-full-width ">
                                <span matPrefix>
                                    <mat-icon matPrefix>account_box</mat-icon>
                                </span>
                                <mat-label>Select Sessions</mat-label>
                                <mat-select placeholder="selectSession" formControlName="selectSession" multiple>
                                    <mat-option *ngFor="let session of sessionData" [value]="session.sessionId">
                                        {{session.sessionName}} {{session.sessionTitle}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="registerEventForm.controls['selectSession'].touched &&registerEventForm.controls['selectSession'].invalid
                                    || registerEventForm.controls['selectSession'].errors">
                                    <small *ngIf="registerEventForm.controls['selectSession'].hasError('required')">
                                        Please fill out this field!
                                    </small>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row" *ngIf="questionType.length">
                        <div *ngFor="let question of questionType">
                            <ng-container [ngSwitch]="question.type.label">
                                <mat-form-field class="example-full-width w-full" *ngSwitchCase="'Simple text'">
                                    <span matPrefix>
                                        <mat-icon>question_answer</mat-icon>
                                    </span>
                                    <mat-label>{{ question.text }}</mat-label>
                                    <input matInput [formControlName]="question.customRegistrationFieldId"
                                        [value]="question.answer">

                                    <mat-error *ngIf="returnControl(question.customRegistrationFieldId)?.touched && returnControl(question.customRegistrationFieldId)?.invalid
                                        || returnControl(question.customRegistrationFieldId)?.errors">
                                        <small
                                            *ngIf="returnControl(question.customRegistrationFieldId)?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width w-full" *ngSwitchCase="'Single choice'">
                                    <span matPrefix>
                                        <mat-icon>question_answer</mat-icon>
                                    </span>
                                    <mat-label>{{ question.text }}</mat-label>
                                    <mat-select [formControlName]="question.customRegistrationFieldId"
                                        (selectionChange)="checkDependent($event , question)">
                                        <mat-option *ngFor="let option of question.choices" [value]="option">{{
                                            option }}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="returnControl(question.customRegistrationFieldId)?.touched && returnControl(question.customRegistrationFieldId)?.invalid
                                    || returnControl(question.customRegistrationFieldId)?.errors">
                                        <small
                                            *ngIf="returnControl(question.customRegistrationFieldId)?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="example-full-width w-full" *ngSwitchCase="'Multiple choice'">
                                    <span matPrefix>
                                        <mat-icon>question_answer</mat-icon>
                                    </span>
                                    <mat-label>{{ question.text }}</mat-label>

                                    <!-- (selectionChange)="onSelectionChange($event, question.customRegistrationFieldId)" -->
                                    <mat-select [formControlName]="question.customRegistrationFieldId" multiple
                                        #selectCtrl (selectionChange)="checkDependent($event , question)">
                                        <ng-container *ngFor="let option of question.choices">
                                            <mat-option [value]="option" ngDefaultControl>
                                                {{ option }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error *ngIf="returnControl(question.customRegistrationFieldId)?.touched && returnControl(question.customRegistrationFieldId)?.invalid
                                        || returnControl(question.customRegistrationFieldId)?.errors">
                                        <small
                                            *ngIf="returnControl(question.customRegistrationFieldId)?.hasError('required')">
                                            Please fill out this field!
                                        </small>
                                    </mat-error>
                                </mat-form-field>

                                <ng-container *ngSwitchCase="'Boolean (yes/no)'">
                                    <span matPrefix>
                                        <mat-icon style="margin-bottom: -1%;
                                      margin-right: 6px;">question_answer</mat-icon>
                                    </span>
                                    <mat-label>{{ question.text }}</mat-label>
                                    <mat-radio-group [formControlName]="question.customRegistrationFieldId"
                                        [value]="question.customRegistrationFieldId">
                                        <mat-radio-button value="Yes"
                                            (change)="checkDependent($event, question)">Yes</mat-radio-button>
                                        <mat-radio-button value="No"
                                            [checked]="question.customRegistrationFieldId == 'No'"
                                            (change)="checkDependent($event, question)">No</mat-radio-button>
                                    </mat-radio-group>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="btnresponsive d-flex flex-column flex-sm-row mt-4">
                        <div class="col-12 col-sm-6 mb-2 mb-sm-0">
                            <button *ngIf="!data" type="button"
                                class="btn-cancel rounded-xl btn shadow-lg btn-green fw-bold" (click)="back()"
                                [matTooltip]="'Back'">
                                <mat-icon class="align-middle">arrow_back</mat-icon>
                                Back
                            </button>
                        </div>
                        <div class="col-12 col-sm-6 text-center text-sm-end">
                            <button type="button" class="rounded-xl btn shadow-lg btn-green fw-bold"
                                (click)="submit(registerEventForm)" [matTooltip]="'Next'" [disabled]="isSubmitted">
                                Next <mat-icon class="align-middle">arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
</div>