import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({

    providedIn: 'root'

})

export class CommonService {
    apiURL = environment.apiURL;
    public breadcrumb: string = "";
    // public breadcrumbsData : any 

    registrationFormSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    groupRegistrationFormSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    eventDetail: BehaviorSubject<any> = new BehaviorSubject(null);
    wishlistData: BehaviorSubject<any> = new BehaviorSubject(null)
    // beredcrubmsData: BehaviorSubject<any> = new BehaviorSubject(null)
    breadcrumbsData = new BehaviorSubject<any>(null);
    crmUrlData = new BehaviorSubject<any>(null)
    registrationSubmitData = new BehaviorSubject<any>(null)
    attendeecartdDatasubmit = new BehaviorSubject<any>(null)
    passDataObj = new BehaviorSubject<any>(null)
  updateBreadcrumbsData(data: any): void {
    this.breadcrumbsData.next(data);
  }
    constructor(public httpClient: HttpClient) {
    }
    //get data


    get(method: string, data?: any): Observable<any> {
        return this.httpClient.get(method);
    }

    //Insert And update operation

    insertUpdateDetail(method: string, body: any) {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.post(this.apiURL + method, body, {
            headers,
            responseType: "text",
        });
    }
    //Insert And update operation with response type json

    insertUpdateDetailWithJsonResp(method: string, body: any): Observable<any> {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.post(this.apiURL + method, body, {
            headers,
            responseType: "json",
        });
    }

    // Delete Operation

    deleteDetail(method: string, body: any) {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.post(this.apiURL + method, body, {
            headers,
            responseType: "text",
        });
    }



    //Get List or Object

    getResult(method: string, dtParams: any): Observable<any> {
        return this.httpClient.get(this.apiURL + method, dtParams);
    }



    getResultByNew(method: string, head: HttpHeaders): Observable<any> {
        const headers = head;
        return this.httpClient.get(this.apiURL + method, { headers });
    }



    //Insert And update operation with json response
    post(method: string, body: any): Observable<any> {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.post(this.apiURL + method, body, {
            headers,
            responseType: "json",
        });
    }

    //Post Js Report Data

    // postJsReport(method: string, body: any): Observable<any> {
    //     var url = "https://solzit.jsreportonline.net/api/report";
    //     var username = "ankur.d@solzit.com";
    //     var password = "Solzit@123";
    //     const headers = new HttpHeaders()
    //         //.set('authorization', "Basic Auth " + btoa("username:"+username + ':' + password));

    //         .set('Authorization', "Basic " + btoa(username + ':' + password));
    //     return this.httpClient.post(url + method, body, { headers });
    // }



    //get data from json file

    // loadJSONFileData(jsonPath): Observable<any> {

    //     return this.httpClient.get(jsonPath);

    // }
}