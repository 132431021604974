import { API_Constants } from '../services/API_Constants';
// import { PaymentDetailsRequest } from './../models/payment_details_request';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { TokenizationRequestViewModel } from '../models/tokenization_request_viewmodel';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private httpClient: HttpClient) { }

  // private apiKey = "sk_test_51OG0c9K8zdW8voHF7EJ5hDcipKKOyzxDVFmLxUJkK4vFLTg5yABNaOrrHaahfortdWwugyuKqij2HAAbd5MuUIj800YFCuHApu"; // pradeep stripe key
  private apiKey = "sk_test_51O2oRuBDvrCiJmRu86pvjFD0zmoMsAgLLglmqi1imNHcxz1IV4iTCoy30EkIsAyWZABhp4MQb5GC0XwFyx7h3wA800HMBwsDc0"     //Sovit Client Stripe Account

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.apiKey}`
    }),
  };


  private cardDetails = new BehaviorSubject<any>(null);
  latestCardDetails = this.cardDetails.asObservable();

  getPaymentDetailsForAdhoc(paymentId: string
  ): Observable<any> {
    const url = API_Constants.baseURL + API_Constants.getPaymentDetailsForAdHoc + paymentId;
    return this.httpClient.get<any>(
      url,
    );
  }

  updatePaymentDetails(body: any): Observable<any> {
    const url = environment.apiURL + API_Constants.updatePaymentDetails;
    return this.httpClient.post<any>(
      url,
      body,
      this.httpOptions
    );
  }

  updateCardDetails(card: any) {
    this.cardDetails.next(card);
  }

   // Method to revert payment
  //  refundPayment(body: any): Observable<any> {
  //   const url =  API_Constants.revertPayment;
  //   return this.httpClient.post<any>(
  //     url,
  //     body,
  //     this.httpOptions
  //   );
  // }



  refundPayment(paymentIntent: string): Observable<any> {
    const url =  API_Constants.revertPayment;
    const body = `payment_intent=${paymentIntent}`;
  
    return this.httpClient.post<any>(
      url,
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${this.apiKey}`
        }),
      }
    );
  }
  
}
