import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/services/common.service';
import { EventsService } from 'src/services/events.service';

@Component({
  selector: 'app-add-attendees',
  templateUrl: './add-attendees.component.html',
  styleUrls: ['./add-attendees.component.scss']
})
export class AddAttendeesComponent implements OnInit {
  attendeesForm: FormGroup = new FormGroup({});
  attendeesCount = 0;
  registrationFormData: any;
  numberOfGroupSeatReg: any;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toaster: ToastrService,
    private eventService: EventsService,
    private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    
    this.attendeesForm = this.formBuilder.group({
      attendees: this.formBuilder.array([])
    });
    this.numberOfGroupSeatReg = localStorage.getItem('numberOfGroupSeatReg')
    this.numberOfGroupSeatReg = parseInt(this.numberOfGroupSeatReg);

    this.checkAttendeeFlag();
  }
  get attendees() {
    return this.attendeesForm.get('attendees') as FormArray;
  }

  checkAttendeeFlag() {
    this.commonService.registrationFormSubject.subscribe((regResp) => {
      regResp.isGroupLeader = false;
      this.registrationFormData = regResp;
      if (this.registrationFormData?.isAttendee) {
        this.addAttendeeForm(this.registrationFormData);
      }
    });
  }

  addAttendeeForm(data: any = null) {
    const attendee = this.formBuilder.group({
      firstName: [data ? data.firstName : '', Validators.required],
      lastName: [data ? data.lastName : '', [Validators.required]],
      mobileNumber: [data ? data.mobileNumber :'', [Validators.required,  Validators.pattern('^[0-9]+$')]],
      email: [data ? data.email : '', [Validators.required,Validators.email]],
      isGroupLeader: [data ? data.isGroupLeader : false]
    });

    if (this.attendeesCount === this.numberOfGroupSeatReg) {
      this.toaster.warning('Number of group attendee full', 'Error')
    }
    else {
      this.attendees.push(attendee);
      this.attendeesCount++;
    }

  }

  removeAttendee(index: number) {
    this.attendees.removeAt(index);
    this.attendeesCount--;
  }
  next( ) {

    this.spinner.show();
    const formData = this.attendeesForm.value;
    let object = {
      firstName: this.registrationFormData.firstName, 
      lastName: this.registrationFormData.lastName, 
      email: this.registrationFormData.email, 
      mobileNumber: this.registrationFormData.mobileNumber, 
      eventId: this.registrationFormData.eventId, 
      passId: this.registrationFormData.passId, 
      sessionLists: this.registrationFormData.sessionLists, 
      seatnumber: this.registrationFormData.seatnumber, 
      applyPromoCode: "string", 
      customQueRegistrationId: null, 
      responseLists: [], 
      attendeeLists: this.attendeesForm.value.attendees, 
      isGroupRegistration: true 

    } 
    //***********************Post API Of Create Lead Success fully **************************************/    
    this.eventService.CreateEventRegistration(object).subscribe((leadResp: any) => { 
      if (leadResp.isSuccessful) { 
        this.spinner.hide(); 
        if (leadResp.messageDetail.message_code === "200") { 
          this.toaster.success(leadResp.messageDetail.message, 'Confirmation', { 
          }); 
          this.spinner.hide(); 
        } 
        else if (leadResp.messageDetail.message_code === "500"){ 
          this.toaster.error(leadResp.messageDetail.message, 'Confirmation', { 
          }); 
          this.spinner.hide();              
        } 
      } 
    }) 
  }               
}                                                    


