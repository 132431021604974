<!-- <div class="bcherobanner__background">
  <div class="background-image-container">
    <img class="bg-image" src="../../assets/images/ContactUsImg.png" alt="">
  </div>
  <div class="bcherobanner__center-content">
    <div class="promohighlight">
      <div class="bc-promo-highlight  " style="background-color:rgba(255,255,255,0.4)" data-module="bc-promo-highlight"
        id="mod-bc-promo-highlight-1">
        <div class="bc-promo-highlight__block">
          <div class="bc-promo-highlight__header g-color-white">
            <h1 class="bc-promo-highlight__title ">
              <span style="color:000000">
                CONTACT BUSINESS NSW
              </span>
            </h1>
          </div>
        </div>
        <div class="bc-promo-highlight__content">
          <span class="bc-promo-highlight__content__text ">
            We exist to give a voice to business
          </span>
        </div>
        <div class="button bc-promo-highlight__control">
        </div>
      </div>
    </div>
    </div>
</div> -->



  <h2 mat-dialog-title *ngIf="eventDetailsData?.policy" class="text-center section-title">Our Policies</h2>
  <mat-dialog-content class="mat-typography" *ngIf="eventDetailsData?.policy">
    <p class="text-justify"> {{eventDetailsData?.policy}}</p>

  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mr-2">
    
    <button mat-button mat-dialog-close tabindex="-1" class="btn rounded-xl btn shadow-lg btn-cancel  fw-bold  text-white"><mat-icon class="align-middle">close</mat-icon>Close</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
  </mat-dialog-actions>

