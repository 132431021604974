import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/services/PaymentService';
import { CommonService } from 'src/services/common.service';
import { EventsService } from 'src/services/events.service';
import { ContactUsComponent } from 'src/shared/contact-us/contact-us.component';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
  registrationObj: any;
  registrationFormData: any;

  eventDetailsId: any;

  codeOfPromoCode: any;
  selectPassPrice: any;
  percentOfDiscountOfPromoCode: any;
  promocode: any
  totalPrice: any;
  discountAmountInRupees: any;
  passName: any;
  passDetails: any;
  eventName: any;
  attendeeLength: any;
  subTotal: any;
  currencyAUD: any;
  value: any;
  seat: any;
  data: any;
  promoCodeApplied: boolean = false;
  overRideTicket: any
  ticketPrice: any;
  invoiceId: any;
  token: any;
  dialogRef: any;
  checked: boolean = false;
  isSubmitted: boolean = false;
  checkboxChecked: boolean = false;
  errorMessage: string = '';
  eventDetailsData: any;

  constructor(
    private commonService: CommonService,
    private eventService: EventsService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private toaster: ToastrService,
    private _route: ActivatedRoute,
    private router: Router,
    public _dialog: MatDialog
  ) {
    this.eventDetailsId = this._route.snapshot.paramMap.get('id');
    this.GetEventDetailsByEventByEventId();
    // this.commonService.setBreadcrumb('Payment-Details');
    this.value = this._route.snapshot.queryParamMap.get('value');
    this.data = this._route.snapshot.queryParamMap.get('Type');
    this.token = this._route.snapshot.queryParamMap.get('Token');
    // this.seat = parseInt(this._route.snapshot.queryParamMap.get('seat'));
    let obj = {
      data: this.data,
      token: this.token
    }


    const isClaimed = sessionStorage.getItem('isClaimed');
    const isCrm = sessionStorage.getItem('isCrm');

    if ((isClaimed != '0') && (isCrm != '0')) {
      this.router.navigate(['/unauthorized']);
    }
    // if (!+sessionStorage.getItem('isClaimed') && !+sessionStorage.getItem('isCrm')) {
    //   this.router.navigate(['/unauthorized']);
    // }
    this.commonService.crmUrlData.next(obj);
    this.commonService.registrationFormSubject.subscribe(regResp => {
      this.registrationFormData = regResp
      this.attendeeLength = regResp?.attendeeLists?.length || 1
    });

    this.commonService.eventDetail.subscribe(eventresp => {
      this.currencyAUD = eventresp?.currency
      this.seat = parseInt(eventresp?.eventSeatAvailable)
    })
  }
  ngOnInit() {

    this.codeOfPromoCode = localStorage.getItem('codeOfPromoCode');
    this.selectPassPrice = localStorage.getItem('selectPassPrice');
    this.percentOfDiscountOfPromoCode = localStorage.getItem('percentOfDiscountOfPromoCode');
    // this.eventName = localStorage.getItem('eventName');
    this.passName = localStorage.getItem('passName');
    this.passDetails = this.passName + ' ' + this.selectPassPrice
    // localStorage.setItem('totalPrice', this.totalPrice);
    this.subTotal = this.selectPassPrice * this.attendeeLength || 0;
    this.ticketPrice = this.selectPassPrice;
    this.totalPrice = this.subTotal
    if (this.data) {
      this.overRideTicket = this.selectPassPrice
      this.ticketOverRide();
    }
    else {
      this.overRideTicket = this.selectPassPrice
    }


    if (this.registrationFormData == null) {
      this.router.navigate(['/event-registration/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
    }

  }
  GetEventDetailsByEventByEventId() {
    this.spinner.show();
    this.eventService.GetEventDetailsByEventByEventId(this.eventDetailsId).subscribe((respEventDetails: any) => {
      if (respEventDetails.isSuccessful === true) {
        this.seat = respEventDetails?.data?.eventSeatAvailable;
        this.eventName = respEventDetails?.data?.eventName
        this.eventDetailsData = respEventDetails?.data
        // this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    })
    this.spinner.hide();
  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 && charCode !== 46 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ticketOverRide() {

    this.subTotal = this.overRideTicket * this.attendeeLength || 0;
    this.ticketPrice = this.overRideTicket;
    this.totalPrice = this.subTotal
  }
  promoCodeApply() {
    if (this.codeOfPromoCode === this.promocode) {
      if (this.percentOfDiscountOfPromoCode > 0) {
        this.promoCodeApplied = true;
        const discountAmount = this.subTotal * (this.percentOfDiscountOfPromoCode / 100);
        // Apply the discount to the total price
        this.totalPrice = this.subTotal - discountAmount;
        // Store the discount amount for display
        this.discountAmountInRupees = discountAmount;


      }
    } else {
      this.toaster.error("Invalid promo code");
    }
  }
  clearPromoCode() {
    // Reset discount amount when promo code is cleared
    this.totalPrice = this.subTotal;
    this.discountAmountInRupees = 0;
    this.promoCodeApplied = false
    this.promocode = ''; // Clear the input field
  }

  back() {
    // this.router.navigate(['/event-registration/'+this.eventDetailsId])
    if (this.value == 'Group') {
      this.router.navigate(['/group-attendee/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
    }
    else {

      this.router.navigate(['/event-registration/' + this.eventDetailsId], { queryParams: { value: this.value, seat: this.seat } });
    }
  }
  submitPay() {

    if (this.checkboxChecked) {
      // Proceed with form submission
    } else {
      this.errorMessage = 'Please accept the terms and conditions by checking the checkbox before proceeding.';
      return
    }

  
    // this.spinner.show();
    this.registrationFormData.totalAmount = parseInt(this.totalPrice);
    this.registrationFormData.ticketPrice = parseFloat(this.ticketPrice);
    this.registrationFormData.promoCodeApplied = this.promoCodeApplied;
    if (this.registrationFormData.totalAmount == 0) {
      let objectdup = {
        bu: "BA",
        salesOrderName:this.eventDetailsData?.eventName, // Assuming you have access to event name
        invoiceDisplayed: false,
        sendTechOneInvoice: true,
        paymentReceived:  true , // You need to determine the payment status
        externalOrderNumber: "", // Assuming result contains Stripe payment id
        paymentReferenceNumber: "0 $ transaction", // Assuming result contains Stripe reference id
        invoiceEmail: this.registrationFormData.email, // Assuming this value is static or provided from somewhere
        salesOrderSource: 100000002,
        paymentType: 100000000,
        products: [
          {
            ba_productid: this.eventDetailsData?.productId, // Return from get event details by eventid APIs
            priceperunit: this.totalPrice, // Price of ticket or can be dynamic
            ba_usecrmproductprice: false, // or false based on your requirement
            manualdiscountamount: 0, // According to the ticket promocode discount or can be dynamic
            quantity: this.registrationFormData.attendeeLists.length, // Number of attendees
            tax: 0, // Tax amount, you need to clarify this value
            ba_productcode:this.eventDetailsData?.productCode // Return from get event details by eventid APIs
          }
        ]
      };
      this.registrationFormData = { ...this.registrationFormData, ...objectdup };

      this.spinner.show();
      this.eventService.CreateEventRegistration(this.registrationFormData).subscribe((registrationresponce: any) => {
        if (registrationresponce.isSuccessful == true) {
          if (registrationresponce.data && registrationresponce?.data?.orderNumber) {

            this.router.navigate(['/thank-you'], {
                    queryParams: {
                      // PaymentReference: this.tokenObj?.card?.brand,
                      // Amount: this.price,
                      Currency: 'inr',
                      Status: true, // check it is hardcode
                      orderNumber: registrationresponce?.data?.orderNumber,
                      Type: this.data
                    },
                  });
              (error: any) => {
                this.spinner.hide();
                this.toaster.error(error.messageDetail.message, 'Error');
              };;
          }
        }
        else if (registrationresponce.isSuccessful == false) {
          this.spinner.hide();
          this.toaster.error(registrationresponce.messageDetail.message, 'Error', {
          });
        }
      },
    
        (error: any) => {
          
          this.spinner.hide();
          this.toaster.error(error.messageDetail.message, 'Error');
        });
        (error: any) => {
          this.spinner.hide();
          this.toaster.error(error.messageDetail.message, 'Error');
        };;
    }
    else {
      this.router.navigate(['/stripe-payment/' + this.eventDetailsId], { queryParams: { totalPrice1: this.totalPrice, value: this.value, seat: this.seat, Type: this.data, Token: this.token } });
      this.spinner.hide();
    }
  }

  openDialog(): void {
    // this.dialogRef = this._dialog.open(ContactUsComponent, {
    //   disableClose: true,
    //   width: '900px',
    //   height: '550px'
    // })
    let url = 'https://www.mybusiness.com.au/privacy-policy'
    window.open(url)
  }
  close() {
    this.dialogRef.close(true)
  }

  checkBoxBtn(event: any) {
    this.checkboxChecked = event.target.checked;
    this.errorMessage = ''; // Clear the error message when the checkbox is checked
    this.isSubmitted = this.validateCheckbox(event.target.checked);
  }

  validateCheckbox(checked: boolean): boolean {
    return checked;
  }

}
