export const environment = {
    production: false,
    apiURL: 'https://d365mkt-api.solzit.com/api/V1/', // dev
    //apiURL: 'https://bznswuatapi.solzit.com/api/V1/', // UAT
    //apiURL : 'https://bnswevents-api-dev.azurewebsites.net/api/V1/',//Client Dev
    // apiURL : 'https://bnswevents-api-uat.azurewebsites.net/api/V1/',//Client UAT
    checkout_URL: 'https://sbcheckout.PayFort.com/FortAPI/paymentPage',
    // stripeKEY: 'pk_test_51OG0c9K8zdW8voHFnwyHisinpdAMp3oSgcOAWnRMGZxjSCyJHt3WBtJ8yVqB9NoHaAw8MAR6hQQJPKUAxzSoe1sG00pfaM8z4V'  //Pradeep Stripe Account
    stripeKEY: 'pk_test_51O2oRuBDvrCiJmRuMkDrTtilGPsPMb6wr7eeUTQBxvvSEF4mVw3vzT2Hof6PVqwZafvFSL5o0cnmrSb1ChUv17fh00MvVvPICH'     //Sovit Client Stripe Account
};
