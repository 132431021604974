import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from 'src/shared/contact-us/contact-us.component';
import { EventRegistrationComponent } from './event-detail/event-registration/event-registration.component';
import { AddAttendeesComponent } from './event-detail/add-attendees/add-attendees.component';
import { PaymentInfoComponent } from './event-detail/payment-info/payment-info.component';
import { WishListComponent } from 'src/shared/wish-list/wish-list.component';
import { SeatDialogComponent } from 'src/shared/seat-dialog/seat-dialog.component';
import { GroupAttendeeComponent } from './event-detail/group-attendee/group-attendee.component';
import { StripePaymentComponent } from './event-detail/stripe-payment/stripe-payment.component';
import { ThankYouComponent } from './event-detail/thank-you/thank-you.component';
import { ErrorComponent } from './event-detail/error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',loadChildren:() => import('./home/home.module').then(h => h.HomeModule)
  },
  {
    path: 'event-details/:id', loadChildren:() => import('./event-detail/event-detail.module').then(e =>e.EventDetailModule)
  },
  {
    path: 'unauthorized', component: UnauthorizedComponent
  },
  {
    path: 'contact-us', component: ContactUsComponent
  },
  {
    path: 'wishList', component: WishListComponent
  },
  {
    path:'event-registration/:id', component:EventRegistrationComponent
  },
  {
    path:'add-attendees', component:AddAttendeesComponent
  },
  {
    path:'payment-info/:id', component:PaymentInfoComponent
  },
  {
    path:'seat-dialog', component:SeatDialogComponent
  },
  {
    path:'group-attendee/:id', component:GroupAttendeeComponent
  },
  {
    path:'stripe-payment/:id', component:StripePaymentComponent
  },
  {
    path:'thank-you', component:ThankYouComponent
  },
  {
    path:'error', component:ErrorComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
