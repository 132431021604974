import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { EventsService } from 'src/services/events.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  registrationForm: FormGroup;
  eventDetailsId: any;
  eventDetailsData: any;

  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private eventService: EventsService,
    private route: ActivatedRoute,
    private toaster: ToastrService
  ) {
    // this.registrationForm = this.formBuilder.group({
    //   name: ['', Validators.required],
    //   email: ['', [Validators.required, Validators.email]],
    //   username: ['', Validators.required],
    //   password: ['', Validators.required]
    // });
  }

  ngOnInit() {
    this.spinner.show();
    this.eventDetailsId = localStorage.getItem('eventDetailsId')
    // this.eventDetailsId = this.route.snapshot.paramMap.get('id');
    this.GetEventDetailsByEventByEventId();
  }

  //**********************************API of Get  Event Details By Event B yEvent Id  *********************************/
  GetEventDetailsByEventByEventId() {
    
    this.spinner.show();
    this.eventService.GetEventDetailsByEventByEventId(this.eventDetailsId).subscribe((respEventDetails: any) => {
      if (respEventDetails.isSuccessful === true) {
        this.eventDetailsData = respEventDetails.data;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    }),
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.messageDetail.message, 'Error');
      }; 

  }
  onSubmit(): void {
    // Submit the form data to a server here
  }
}