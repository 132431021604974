import { Component } from '@angular/core';

@Component({
  selector: 'app-seat-dialog',
  templateUrl: './seat-dialog.component.html',
  styleUrls: ['./seat-dialog.component.scss']
})
export class SeatDialogComponent {
  seatImage: any;



  ngOnInit(): void {
    this.seatImage = localStorage.getItem('seatImage');
  }

  viewFullScreen(){
    let url =this.seatImage
    window.open(url);
  
  }
  

}

