<div class="text-center">
    <div class="grid grid-cols-7">
        <div class="col-span-2 mx-auto"></div>
        <div class="col-span-3 mx-auto">
            
            <img src="\assets\images\img.jpg" class="">
        </div>
        <div class="col-span-2 mx-auto"></div>
    </div>
    <p class="text-4xl fw-bold text-center text-danger">Unauthorized Access</p>
</div>
