import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  ErrorMessage: any;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const state = {
        errorCode: params['errorCode'],
        errorMessage: params['errorMessage'],
      };
      this.ErrorMessage = state.errorCode + ' ' + state.errorMessage;
    });
  }
}