<section *ngIf="!data">
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex align-item-center justify-content-between py-3">
        <div>
          <h5 class="m-0 cus-font">Driving better outcomes and delivering results for business - Join Business NSW</h5>
        </div>
       
        <div class="d-flex align-item-center">
          <div class="demo-section text-end mr-8">
            <mat-icon matBadge="{{whislistDataLength}}" matBadgeColor="warn" class=""(click)="Wishlist()">favorite</mat-icon>
              <span class="cdk-visually-hidden">
                Example with a home icon with overlaid badge showing the number 15
              </span>
          </div>
          <div>
            <a class="m-0 cus-font pr-5" style="color: black;">Contact Us</a>
          </div>
          <div>
            <a class="m-0 cus-font" style="color: black;">Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="bc-header__outer-container js-bc-header bc-header-is-rich bc-header__outer-container--hero" *ngIf="!data">
  <header class="bc-header first-header layout-0">
    <div class="bc-header__logo-container">
      <div class="bc-header__logo-link">
        <div class="bc-header-logo__second bc-header-logos"
          style="background-image: url('../../assets/Logo/standard-inverted.png');">
        </div>
      </div>
    </div>
    <nav class="bc-nav">
      <ul class="bc-nav__0-level">
        <li class="bc-nav__0-level-container ">
          <div class="bc-nav__0-level-item summary__container text-sz-18">
            <a>
              Campaigns
            </a>
          </div>
        </li>
        <li class="bc-nav__0-level-container">
          <div class="bc-nav__0-level-item summary__container text-sz-18">
            <a>
              Advocacy
            </a>
          </div>
        </li>
        <li class="bc-nav__0-level-container ">
          <div class="bc-nav__0-level-item summary__container text-sz-18">
            <a>
              Network
            </a>
          </div>
        </li>
        <li class="bc-nav__0-level-container ">
          <div class="bc-nav__0-level-item summary__container text-sz-18">
            <a>
              Media
            </a>
          </div>
        </li>
        <li class="bc-nav__0-level-container ">
          <div class="bc-nav__0-level-item summary__container text-sz-18">
            <a>
              Membership
            </a>
          </div>
        </li>
        
        <li class="bc-nav__0-level-container ">
          <div class="bc-nav__0-level-item summary__container text-sz-18">
            <a [routerLink]="['']" class="evntColor">
              Events
            </a>
          </div>
        </li>
      </ul>
    </nav>
    <div class="bc-searchbar-searchIcon bc-searchbar-searchIcon--search bc-header">
      <mat-icon>search</mat-icon>
    </div>
  </header>
</div>

<router-outlet></router-outlet>

<footer *ngIf="!data">
  <div class="container">
    <div class="row py-5">
      <div class="col-md-2 footer-column">
        <ul class="nav flex-column">
          <img src="../../assets/Logo/standard-inverted (1).png" alt="logo" style="width: 150px !important;">
        </ul>
      </div>
      <div class="col-md-1 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item mb-3 ">
            <span class="footer-title">About</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">About us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Executive Leadership</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Governance</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Acknowledgement of Country</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item mb-3">
            <span class="footer-title">Advocacy</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Why we advocate</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Advocacy agenda</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Submissions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Surveys</a>
          </li>
        </ul>
      </div>
      <div class="col-md-1 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item mb-3">
            <span class="footer-title">Network</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">NSW Metropolitan</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">NSW Regional</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Membership</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Industrial Relations</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item mb-3">
            <span class="footer-title">Media</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Media Releases</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Media Contacts</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Member updates</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Advocacy in Action: News</a>
          </li>
        </ul>
      </div>
      <div class="col-md-1 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item mb-3">
            <span class="footer-title">Membership</span>
          </li>
          <li class="nav-item">
            <a class="nav-link mr-4" href="#">Join Business NSW</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Business Insights</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">IR Advisor</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Regional Leaders</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Membership Terms</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item mb-3">
            <span class="footer-title">Events</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">2022 Business Awards</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">NSW Tourism Awards 2022</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Top Tourism Town Awards 2022</a>
          </li>
        </ul>
      </div>
      <div class="col-md-1 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item mb-3">
            <span class="footer-title">Contact Us</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-2 box">
      </div>
      <div class="col-md-2 box">
        <ul class="list-inline quick-links">
          <li class="list-inline-item">
            <a href="#">Website terms of use</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2 box">
        <ul class="list-inline quick-links">
          <li class="list-inline-item">
            <a href="#">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 box">
        <span class="copyright quick-links">
          © 2023 Business NSW. All rights reserved
        </span>
      </div>
      <div class="col-md-3 box">
        <ul class="list-inline social-buttons social-btn-custom">
          <li class="list-inline-item">
            <span class="mr-3">Follow us on</span>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="fab fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>