import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card'
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from './contact-us/contact-us.component';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { MatDialogModule} from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { WishListComponent } from './wish-list/wish-list.component';
import { SeatDialogComponent } from './seat-dialog/seat-dialog.component';
// import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { environment } from 'src/environments/environment';
import { NgxStripeModule } from 'ngx-stripe';
import { MatBadgeModule} from '@angular/material/badge'

@NgModule({
  declarations: [
    ContactUsComponent,
    WishListComponent,
    SeatDialogComponent
  ],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonToggleModule,
    MatInputModule,
    CarouselModule,
    NgbCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgIf,
    MatSelectModule,
    MatDialogModule,
    NgxSpinnerModule,
    MatTooltipModule,
    ToastrModule.forRoot(),
    MatCheckboxModule,
    MatRadioModule,
    NgxStripeModule.forRoot(environment.stripeKEY),
    MatBadgeModule
  ],
  exports: [
    MatSlideToggleModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonToggleModule,
    MatInputModule,
    CarouselModule,
    NgbCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    NgIf,
    MatSelectModule,
    MatDialogModule,
    NgxSpinnerModule,
    ToastrModule,
    MatCheckboxModule,
    MatRadioModule,
   // NgxStripeModule.forRoot(environment.stripeKEY),
   MatBadgeModule
  ],
  providers: [Validators,ErrorStateMatcher,FormControl,FormGroupDirective,NgForm],
 
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
