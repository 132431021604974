<!-- <div class="p-4">
    <ng-container *ngIf="wishlistDetails && wishlistDetails.length > 0; else noDataTemplate">
        <div class="card my-2 p-2 rounded-xl shadow-lg" *ngFor="let data of wishlistDetails; let i = index ">
            <div class="d-flex p-1 mx-2">
                <div class="flex-grow-1 ml-4">
                    <table class="table table-bordered">
                        <tr>
                            <th style="width: 40%;">Event Name</th>
                            <th style="width: 40%;">Event Type</th>
                            <th style="width: 20%;">Start Date Time</th>
                        </tr>
                        <tr>
                            <td style="width: 40%;">{{data.eventName}}</td>
                            <td style="width: 40%;">{{data.eventType?.label}}</td>
                            <td style="width: 20%;">{{data.eventStartDateTime | date: 'medium'}}</td>
                        </tr>
                    </table>
                </div>

                <div>
                    <button type="button" class="bg-blue-100 text-blue-800 p-1 rounded-full" (click)="edit(data)"
                        [matTooltip]="'Edit Event'">
                        <mat-icon class="align-middle">remove_red_eye</mat-icon>
                    </button>
                    <button type="button" class="bg-red-100 text-red-800 p-1 rounded-full" (click)="removeWishlist(i)"
                        [matTooltip]="'Remove Event'">
                        <mat-icon class="align-middle">delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #noDataTemplate>
        <div class="alert alert-info text-center fs-6 fw-bold" role="alert">
            Empty Wishlist
        </div>
    </ng-template>
</div> -->










<div class="p-4">
    
<!-- Check if there is data in wishlistDetails -->
<ng-container *ngIf="wishlistDetails && wishlistDetails.length > 0; else noDataTemplate">
    <div class="container bradcum-max-width">
        <div class="row p-4 d-flex">
            <div class="card p-2 m-2 col-md-3 cardHeight" style="width: 21rem;"
                *ngFor="let details of wishlistDetails; let i = index ">
                <img *ngIf="details.eventImageUrl" src="{{details.eventImageUrl}}" class="rounded shadow-lg photo-icon1"
                    alt="...">
                <img *ngIf="!details.eventImageUrl " class="photo-icon1 rounded shadow-lg"
                    src="../../assets/events/eventImg2.jpg">
                <div class="card-body">
                    <div class="flex actions text-end">
                        <button type="button" class="bg-blue-100 text-blue-800 p-1 rounded-full" (click)="edit(details)"
                            [matTooltip]="'View Event'">
                            <mat-icon class="align-middle">remove_red_eye</mat-icon>
                        </button>
                        <button type="button" class="bg-red-100 text-red-800 p-1 rounded-full"
                            (click)="removeWishlist(i)" [matTooltip]="'Remove Event'">
                            <mat-icon class="align-middle">delete</mat-icon>
                        </button>
                    </div>
                    <div>

                    </div>
                    <p class="fw-bold textDot">{{details.eventName}}</p>
                    <p class="fw-fw-bolder textDot">{{details.eventLocation}}</p>
                    <p class="fw-fw-bolder textDot">{{details.eventStartDateTime | date }}</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Template for no data -->
<ng-template #noDataTemplate>
    <div class="alert alert-info text-center fs-6 fw-bold" role="alert">
        Empty Wishlist
    </div>
</ng-template>

</div>
