import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  apiURL: string = "";
  constructor(
    public httpClient: HttpClient,
    private commonService: CommonService

  ) {
    this.apiURL = environment.apiURL;
  }

  //Event List Based On Event Selection 
  // GetEventsList(obj: any) {
  //   return this.httpClient.post(this.commonService.apiURL + 'GetEventsList', obj)
  // }
  //Event List Based On Event Selection 
  GetEventsModeList(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetEventList?EventMode=' + id)
  }



  GetSpeakersByEventId(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetSpeakersByEventId?Eventid=' + id)
  }

  GetSessionByEventId(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetSessionByEventId?Eventid=' + id)
  }

  GetSponsorsByEventId(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetSponsorsByEventId?Eventid=' + id)
  }

  GetEventDetailsByEventByEventId(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetEventDetailsByEventByEventId?Eventid=' + id)
  }

  GetAuthentication(id:any){
    return this.httpClient.get(this.commonService.apiURL + 'Auth/CheckAuthenticateStatus?AuthId=' + id)
  }

  //###################### Post API Of Creat Lead ####################################################
  CreateLead(obj: any) {
    return this.httpClient.post(this.commonService.apiURL + 'Leads/CreateLead', obj)
  }

  // *********************** Get  Pass Or Ticket Information By EventId 
  GetPassInformationByEventId(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetPassInformationByEventId?Eventid=' + id)
  }

  // *********************** Get  Custom Logic Question  By EventId *********************************
  GetCustomLogicQuestionByEventId(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetCustomLogicQuestionByEventId?Eventid=' + id)
  }

  // ##################################### Create Event Registration #######################################
  CreateEventRegistration(obj: any) {
    return this.httpClient.post(this.commonService.apiURL + 'Events/CreateEventRegistration', obj)
  }

  GetSessionByEventIdAndPassId(passId: any , eventId:any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetSessionByEventIdAndPassId?Passid=' + passId + "&Eventid=" + eventId )
  }
  GetPassesWithELigibleSessionByEventId(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Events/GetPassesWithELigibleSessionByEventId?Eventid=' + id)
  }
  GetInvoiceDetailsByInvoiceId(id: any) {
    return this.httpClient.get(this.commonService.apiURL + 'Invoice/GetInvoiceDetailsByInvoiceId?OrderId=' + id)
  }
}
